import { useSdk } from '~/sdk';
import type {
  GetCartItemsResponse,
  UpdateCartItemRequest,
  AddOrRemoveCouponRequest,
} from '@vue-storefront/prestashop-api';
import useAnalytics from '../useAnalytics';

export const useCart = () => {
  const route = useRoute();
  const { addOrRemoveFromCart } = useAnalytics();
  const loading = useState(() => false);
  const auth = useAuth();
  const isAuthenticated = computed(() => auth.isAuthenticated);

  const { data: cartData, status, refresh: fetchCart } = useAsyncData(
    'cartData',
    () => useSdk().commerce.getCartItems({ id: route.params.id as string }),
    { immediate: false, watch: [isAuthenticated] },
  );

  async function addToCart(product: UpdateCartItemRequest) {
    loading.value = true;

    await useSdk().commerce.updateCartItem({
      id_product: product.id_product,
      id_product_attribute: product.id_product_attribute,
      qty: product.qty,
      op: product.op || 'up',
    });

    if (product.op != 'down') {
      addOrRemoveFromCart(product, 'add_to_cart');
    }

    await fetchCart();
  }

  async function removeFromCart(product: UpdateCartItemRequest) {
    loading.value = true;

    await useSdk().commerce.removeCartItem({
      id_product: product.id_product,
      id_product_attribute: product.id_product_attribute,
    });

    addOrRemoveFromCart(product, 'remove_from_cart');

    await fetchCart();
  }

  async function applyCoupon(params: AddOrRemoveCouponRequest) {
    loading.value = true;

    const data = await useSdk().commerce.addOrRemoveCoupon({
      addDiscount: params.addDiscount,
      discount_name: params.discount_name,
    });

    await fetchCart();

    return data;
  }

  async function removeCoupon(params: AddOrRemoveCouponRequest) {
    loading.value = true;

    await useSdk().commerce.addOrRemoveCoupon({
      deleteDiscount: params.deleteDiscount,
    });

    await fetchCart();
  }

  return {
    data: computed(() => cartData.value || null),
    loading: computed(() => status.value === 'pending' || loading.value),
    execute: fetchCart,
    refresh: fetchCart,
    addToCart,
    removeFromCart,
    applyCoupon,
    removeCoupon,
  };
};
